.paperContainer {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Adjust the width to occupy 90% of the viewport width */
  max-width: min(60vw,400px); /*
  height: auto; /* Allow the height to adjust based on content */
  padding: 40px 25px; /* Add some padding for spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white; /* Add a background color for better contrast */
  border-radius: 10px; /* Add border radius for a softer appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  display: block;
}

.errorText {
  color: var(--red);
  margin-bottom: 2rem;
}

@media screen and(max-width: 768px) {
.paperContainer{
  top:50%;
}
}
  

  .adminActionsItem {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--black);
    border-radius: 4px;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .adminActionsItem:hover {
    cursor: pointer;
    background: #e3eaf5;
    color: #333;
  }
  
  .action-dots-icon {
    cursor: pointer;
    color: var(--black);
    transition: background-color 0.2s ease, transform 0.1s ease;
  }
  
  .action-dots-icon:hover {
    background-color: #f4f4f4;
    transform: scale(1.1);
  }
  
.bodyContainer {
    margin: 0 auto; 
    position: relative;
    top: 30px;
    max-width: 1050px;
    display: flex;
    flex-direction: column;
}

.header_container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
    flex-wrap: wrap;
    gap: 8px;
}
.header_buttons{
    margin-left: auto;
    display: flex;

}

.header_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 16px;
    text-align: center; /* Center header text */
    margin-right: auto;
    
}

.searchBar {
    width: 100%; 
    width: 300px; 
}



.labelItem {
    background: #FFFFFF;
    border-radius: 4px;
    height: 25px;
    padding-right: 12px;
    padding-left: 12px;
    margin-left: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #616E87;
    border: 1px solid #FFFFFF;
    max-width: 80px;
    cursor: pointer;
    margin-top: 8px;
   
}


.drop_down_list {
    z-index: 1600 !important;
    background-color: white !important;
    box-shadow: 0px 4px 16px 4px rgba(24, 26, 46, 0.3);
    border-radius: 8px !important;
    padding:5px 10px !important;
    font-size: 14px;
}

.filterListItem {
    display: flex;
    align-items: center;
    height: 30px;
    left: 0;
    width: 100%;
    border-radius: 6px;
    color: #616E87;
}

.filterListItem:hover {
    cursor: pointer;
    background-color: #EBEEF7;
}

.fillterIcon{
    margin-right: auto;
    display: flex;
    align-items: center;
  
}

.label_text {
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
}


.filterCard {
    min-height: 60px;
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap; /* Enable flex wrapping */
}




.filterbarButtonsContainer{
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-wrap: wrap; 
    gap: 8px;
    padding: 0 12px;
}

  /* Adjustments for tablet and mobile screens */
  @media (max-width: 768px) {
    .filterCard {
      margin-bottom: 15px; 
      flex-direction: column;
      align-items: flex-start; 
    }
  
    .filterCard > * {
        margin-bottom: 10px;
    }

    .header_text {
        font-size: 20px;
    }
  
}

@media (max-width: 420px) {
    /* .filterbarButtonsContainer{
        margin-right: auto;
        justify-content: flex-start;
    } */

    .searchBar {
        width: 220px; 
    }
}

.disabled-row{
    background-color: var(--light_grey) !important;
}
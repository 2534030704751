.text_right{
  margin-top:1vh;
  margin-right:5vw;
  text-align: right;
  font-weight: bold;
  opacity:0.9;
}

.center {
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
} 

@font-face { font-family: roboto-regular; 
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); } 
html, body {
  margin: 0 !important;
}
.bouncing-loader {
  position: relative;
  margin-top: 30%;
  display: flex;
  justify-content: center;
}


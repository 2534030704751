:root {
  --carteav: hsl(197, 100%, 43%);
  --carteav_dark: #077eb1;
  --carteav_light: rgba(0, 167, 238, 0.2);
  --white: #faf0e6;
  --black: #070A0E;
  --light_black: #24292e;
  --red:#FF3239;
  --light_red: hsl(357, 100%, 66%);
  --grey:#616E87;
  --light_grey: #EBEEF7;
  --green:#86E896;
  --light_green:#B2F9B2;
  --dark_green: #0DD02C;
  --input_color:#f9f2f2;
}





body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    /* Disable select text from app  */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #F0F0F3;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.display_center{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.center_column{
  display: flex;
  flex-direction: column;
  align-items: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}


.black-scroll-bar-container ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.black-scroll-bar-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.black-scroll-bar-container ::-webkit-scrollbar-thumb {
  background: #070A0E;
}

.black-scroll-bar-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.black-scroll-bar-container::-webkit-scrollbar-x {
  width: 5px !important;
}

.black-scroll-bar-container::-webkit-scrollbar-track-x {
  background: #f1f1f1;
}

.black-scroll-bar-container::-webkit-scrollbar-thumb-x {
  background: #070A0E;
}

.black-scroll-bar-container::-webkit-scrollbar-thumb-x:hover {
  background: #555;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
     outline: none;
}

input[type=checkbox] {
  position: relative;
	border: 2px solid #000;
	border-radius: 2px;
	background: none;
	cursor: pointer;
	line-height: 0;
	margin: 0 .6em 0 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 15px;
	width: 15px;
	-webkit-appearance: none;
  opacity: .5;
}

input[type=checkbox]:hover {
  opacity: 1;
}
.sweetalert2-dialog {
  z-index: 2000 !important;
}

input[type=checkbox]:checked {
  background-color: rgb(49, 46, 46);
  opacity: 1;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 8px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -2px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px; /* Change font size to 14px for screens up to 768px */
  }
}

/* Media query for larger screens */
@media screen and (min-width: 1200px) {
  html {
    font-size: 18px; /* Change font size to 18px for screens wider than 1200px */
  }
}


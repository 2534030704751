.generalCarteavButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 4px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    background: var(--carteav);
    color: var(--white);
    padding: 2px 30px ;
}

.generalCarteavButton:hover{
    cursor: pointer;
    background-color: var(--carteav_dark);
}


/* Base button style */
.CustomAddAndEditModal-button{
  width: 110px;
  height: 40px;
  border: none;
  border-radius: 12px;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomAddAndEditModal-save-button {
  background-color: var(--carteav); 
}
.CustomAddAndEditModal-save-button:hover{
  background-color: var(--carteav_dark);
}
.CustomAddAndEditModal-save-button:focus {
  outline: none;
}

.CustomAddAndEditModal-cancel-button {
  background-color: white; 
  color : var(--grey); 
  border: 2px solid #CED5EF;
}

.CustomAddAndEditModal-cancel-button:hover {
  background-color: #CED5EF; 
}

.CustomAddAndEditModal-cancel-button:focus {
  outline: none;
}

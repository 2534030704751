.admin_appBar{
    background-color: var(--black) !important;
    height: 60px !important;
    width: 100vw !important;
    position: relative !important;
}


.topBar{
    position: absolute;
    width:100vw;
    top:0;
    text-align: center;
    padding:1vh;
    font-size: 2rem;
    font-weight: 400;
    color: #009DDC;
    height: 5vh;
    
}
 .logo {
    display: grid;
    place-items: center;
    height: 120px;
    margin-top: 2vh;
    font-size: 2rem;
    font-weight: 500;
    color:#3e5060;
    font-family: 'Mochiy Pop P one', sans-serif;
    
}    
.logo:hover{
    opacity: 0.7;
}
.item {
    position: relative;
    display: flex;
    height:4rem;
    align-items: center;
    place-content:flex-start;
    padding-left:0.7rem;
    margin:1rem;
    font-size:1.3rem;
    font-weight:400;
    color:#3e5060;
    transition: color 0.2s ease-in-out;
}
.item:hover{  
    padding-left:1.2rem;
    color: #009DDC;
}


.i {
    font-size: 1.75rem;
}

